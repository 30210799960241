import React, { useEffect, useState } from 'react';
import { Box, Dialog, DialogContent, DialogActions, Link, Tabs, Tab, Typography, Button, Switch, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Cookies from 'js-cookie';
import CookieOutlinedIcon from '@mui/icons-material/CookieOutlined';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box sx={{ pt: 3, maxHeight: "400px", overflow: "scroll"  }}>{children}</Box>
        )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

const CustomButton = styled(Button)(({theme}) =>({
    width: "100%",
    textTransform: "capitalize",
    ":not(:last-of-type)" : {
        border: "2px solid",
        [theme.breakpoints.down('sm')]: {
            marginBottom: "0.5rem",
        },
    },
    [theme.breakpoints.down('sm')]: {
        marginLeft: "0 !important",
        marginRight: "0 !important"
    },
}));

function CookieConsent() {
    const [cookieBanner, setCookieBanner] = useState(true);
    const [cookieTabValue, setCookieTabValue] = useState(0);
    const [cookieConsent, setCookieConsent] = useState({
        necessary: true,
        marketing: false
    })
    const [expandNecessary, setExpandNecessary] = useState(false);
    const [expandMarketing, setExpandMarketing] = useState(false);

    useEffect(() => {
        if( Cookies.get("cookieConsent") ) {
            setCookieBanner(false);
            setCookieConsent(JSON.parse(Cookies.get("cookieConsent")))
        }
    }, []);

    const handleChangeTab = (event, value) => {
        setCookieTabValue(value);
    };

    const confirmCookie = (type) => {
        if( type === "all" ) {
            setCookieConsent(prevState => {
                return {
                    ...prevState,
                    marketing: true
                }
            })
        } else if( type === "none" ) {
            setCookieConsent(prevState => {
                return {
                    ...prevState,
                    marketing: false
                }
            })
        }
        Cookies.set("cookieConsent", JSON.stringify(cookieConsent), { expires: 365 });
        setCookieBanner(false);
        setCookieTabValue(0);
    }

    const handleChangeConsent = (type, value) => {
        setCookieConsent(prevState => {
            return {
                ...prevState,
                [type]: value
            }
        })
    }

    const marketingCookies = [
        {
            name: "_gcl_au",
            description: "Utilizzato da Google AdSense per sperimentare l'efficacia pubblicitaria su tutti i siti web che utilizzano i loro servizi.",
            expires: "3 mesi",
            type: "HTTP"
        },
        {
            name: "ads/ga-audiences",
            description: "Utilizzato da Google AdWords per coinvolgere nuovamente i visitatori che potrebbero convertirsi in clienti in base al comportamento online del visitatore sui siti web.",
            expires: "Session",
            type: "Pixel"
        },
        {
            name: "DEVICE_INFO (Youtube)",
            description: "Utilizzato per tracciare l'interazione dell'utente con i contenuti incorporati.",
            expires: "179 giorni",
            type: "HTTP"
        },
        {
            name: "VISITOR_INFO1_LIVE (Youtube)",
            description: "Prova a stimare la velocità della connessione dell'utente su pagine con video YouTube integrati.",
            expires: "179 giorni",
            type: "HTTP"
        },
        {
            name: "YSC (Youtube)",
            description: "Registra un ID univoco per statistiche legate a quali video YouTube sono stati visualizzati dall'utente.",
            expires: "Session",
            type: "HTTP"
        },
        {
            name: "OGPC",
            description: "Questo cookie abilita la funzionalità di Google Maps.",
            expires: "18 giorni",
            type: "Analitico"
        },
    ]

    const necessaryCookies = [
        {
            name: "cookieConsent",
            description: "Memorizza lo stato del consenso ai cookie dell'utente per il dominio corrente",
            expires: "1 anno",
            type: "HTTP"
        },
    ]

    return <>
    <Dialog
        open={cookieBanner}
    >
        <DialogContent sx={{ p: 2 }}>
            <Tabs value={cookieTabValue} onChange={handleChangeTab} sx={{
                "& .MuiTabs-flexContainer": { overflow: "scroll" },
                "& button": { fontWeight: "bold", p: "0.5rem" }
            }}>
                <Tab label="Consenso" {...a11yProps(0)} />
                <Tab label="Dettagli" {...a11yProps(1)} />
                <Tab label="Informazioni" {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={cookieTabValue} index={0}>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>Questo sito web utilizza i cookie</Typography>
                <Typography variant="body2">Utilizziamo i cookie per personalizzare contenuti ed annunci, per fornire funzionalità dei social media e per analizzare il nostro traffico. Condividiamo inoltre informazioni sul modo in cui utilizza il nostro sito con i nostri partner che si occupano di analisi dei dati web, pubblicità e social media, i quali potrebbero combinarle con altre informazioni che ha fornito loro o che hanno raccolto dal suo utilizzo dei loro servizi.</Typography>
            </TabPanel>
            <TabPanel value={cookieTabValue} index={1}>
                <Box sx={{ borderBottom: 1, borderColor: "grey.500", pb: 2, mb: 2 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>Necessari</Typography>
                        <Switch label="Necessari" checked={true} readOnly={true} />
                    </Box>
                    <Typography variant="body2">I cookie necessari contribuiscono a rendere fruibile il sito web abilitandone funzionalità di base quali la navigazione sulle pagine e l'accesso alle aree protette del sito. Il sito web non è in grado di funzionare correttamente senza questi cookie.</Typography>
                    <Button onClick={() => setExpandNecessary(!expandNecessary)}>+ Dettagli</Button>
                    { expandNecessary && <Box>
                        { necessaryCookies.map((item, idx) => <Box key={`list-necessary-${idx}`} sx={{ border: 1, borderColor: "grey.500", bgcolor: "grey.200", p: 1, borderRadius: "5px", mb: 2 }}>
                            <Typography variant="body2" sx={{ fontWeight: "bold" }}>{item.name}</Typography>
                            <Typography variant="body2">{item.description}</Typography>
                            <Typography variant="body2">Scadenza: {item.expires}</Typography>
                            <Typography variant="body2">Tipo: {item.type}</Typography>
                        </Box> ) }
                    </Box> }
                </Box>
                <Box sx={{ borderBottom: 1, borderColor: "grey.500", pb: 2, mb: 2 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>Marketing</Typography>
                        <Switch label="Marketing" onChange={(e) => handleChangeConsent('marketing', e.target.checked)} />
                    </Box>
                    <Typography variant="body2">I cookie di marketing vengono utilizzati per tracciare i visitatori sui siti web. La finalità è quella di presentare annunci pubblicitari che siano rilevanti e coinvolgenti per il singolo utente e quindi di maggior valore per editori e inserzionisti di terze parti.</Typography>
                    <Button onClick={() => setExpandMarketing(!expandMarketing)}>+ Dettagli</Button>
                    { expandMarketing && <Box>
                        { marketingCookies.map((item, idx) => <Box key={`list-marketing-${idx}`} sx={{ border: 1, borderColor: "grey.500", bgcolor: "grey.200", p: 1, borderRadius: "5px", mb: 2 }}>
                            <Typography variant="body2" sx={{ fontWeight: "bold" }}>{item.name}</Typography>
                            <Typography variant="body2">{item.description}</Typography>
                            <Typography variant="body2">Scadenza: {item.expires}</Typography>
                            <Typography variant="body2">Tipo: {item.type}</Typography>
                        </Box> ) }
                    </Box> }
                </Box>
                <Typography variant="body2" sx={{ mt: 2 }}>Dichiarazione Cookie aggiornata l'ultima volta il 25/01/23</Typography>
            </TabPanel>
            <TabPanel value={cookieTabValue} index={2}>
                <Typography variant="body2">I cookie sono piccoli file di testo che possono essere utilizzati dai siti web per rendere più efficiente l'esperienza per l'utente.</Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>La legge afferma che possiamo memorizzare i cookie sul suo dispositivo se sono strettamente necessari per il funzionamento di questo sito. Per tutti gli altri tipi di cookie abbiamo bisogno del suo permesso. Ciò significa che i cookie classificati come necessari sono trattati ai sensi dell'articolo 6(1)(f) del GDPR. Tutti gli altri cookie, vale a dire quelli appartenenti alle categorie di preferenze e di marketing, sono trattati ai sensi dell'art. 6(1)(a) del GDPR.</Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>Questo sito utilizza diversi tipi di cookie. Alcuni cookie sono collocati da servizi di terzi che compaiono sulle nostre pagine.</Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>In qualsiasi momento è possibile modificare o revocare il proprio consenso dalla Dichiarazione dei cookie sul nostro sito Web.</Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>Scopra di più su chi siamo, come può contattarci e come trattiamo i dati personali nella nostra <Link target="_blank" href="https://www.trattamix.it/privacy-policy/">Informativa sulla privacy</Link>.</Typography>
            </TabPanel>
        </DialogContent>
        <DialogActions sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}>
            <CustomButton onClick={ () => confirmCookie('none') } variant="outlined">Rifiuta</CustomButton>
            { cookieTabValue === 1 && <CustomButton onClick={ () => confirmCookie('selected') } variant="outlined">Accetta selezionati</CustomButton> }
            { cookieTabValue !== 1 && <CustomButton onClick={ () => setCookieTabValue(1) } variant="outlined">Personalizza</CustomButton> }
            <CustomButton onClick={ () => confirmCookie('all') } variant="contained">Accetta tutti</CustomButton>
        </DialogActions>
    </Dialog>
    { !cookieBanner && <IconButton sx={{ bgcolor: "white", color: "blue", position: "fixed", bottom: "1rem", right: "1rem" }} onClick={() => setCookieBanner(true)}><CookieOutlinedIcon /></IconButton> }
    </>
}

export default CookieConsent;